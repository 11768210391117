.experience-page {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;

    h1.page-title {
        margin-left: 100px;
        margin-top: 100px;
    }
}

.experience-content {
  background-color: #725555;
  width: 85%;
  border-radius: 10px;
  justify-content: center;
  margin-top: 20px;
  overflow: auto;

  h2.company-title {
    margin-left: 20px;
    font-size: 24px;
    color: #9add1f;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
  }

  h3.position-title {
    margin-left: 20px;
    margin-top: -25px;
    font-size: 20px;
    color: #cac3c3;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
  }

  h3.position-date {
    margin-left: 20px;
    margin-top: -25px;
    font-size: 18px;
    color: #cac3c3;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
  }

  .experience-description {
    margin-left: 20px;
    font-size: 16px;
    margin-top: -30px;
    overflow: hidden;
  }

  .company-logo img {
    float: right;
    margin-top: -75px;
    max-width: 125px;
    margin-right: 25px;
    height: auto;
  }

  @media screen and (max-height: 800px) {
    .experience-content {
      margin-top: 10px;
      padding: 10px;
    }

    h2.company-title {
      font-size: 20px;
    }

    h3.position-title {
      font-size: 20px;
    }

    h3.position-date {
      font-size: 18px;
    }

    .experience-description {
      p {
        font-size: 14px;
      }
    }

    .company-logo img {
      margin-top: -50px;
      max-width: 100px;
      margin-right: 15px;
    }
  }
}


@media screen and (max-width: 1200px) {
    .container.experience-page {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
  
      h1.page-title {
        margin-left: 20px;
        margin-top: 20px;
      }

      .company-logo img {
        float: none;
        margin-top: 0;
        margin-right: 20px;
        max-width: 125px;
        margin-left: 20px;
        height: auto;
        overflow: auto;
      }
      .experience-description {
        margin-top: 5px;
      }
    }
  }

  @media screen and (max-height: 850px){
    .bottom-tags {
      display: none;
    }
    
  }

