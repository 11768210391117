.home-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }

    h1 {
        color: white;
        font-size: 60px;
        margin: 0;
        font-weight: 400;

        &::before {
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: #E0B31F;
            font-size: 18px;
            position: absolute;
            margin-top: -10px;
            margin-left: -10px;
            left: 15px;
            opacity: 0.6;
        }

        &::after {
            content: '<h1/>';
            font-family: 'La Belle Aurore';
            color: #E0B31F;
            font-size: 18px;
            position: absolute;
            margin-top: -5px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }

        img {
            width: 70px;
            margin-left: 10px;
            margin-bottom: -13px;
            margin-right: -5px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
    }

    h2 {
        color: #A89350;
        margin-top: 20px;
        font-weight: 400;
        font-size: 20px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: white;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid white;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background: #A89350;
            color: white;
        }
    }
}

@media screen and (max-width: 1200px) {
    .tags,
    .home-page h1:before,
    .home-page h1:after {
      display: none;
    }
  
    .home-page .text-zone {
      position: initial;
      width: 100%;
      transform: none;
      padding: 10px;
      box-sizing: border-box;
    }
  
    .home-page .flat-button {
      float: none;
      display: block;
      margin: 20px auto 0 auto;
      width: 124px;
    }

    .home-page h1 {
        word-wrap: break-word !important;
    }
  }